import React from 'react';
import { useState, useEffect } from 'react';
import '../../src/App.css';
import logo from '../images/logo.png';
import darklogo from '../images/darklogo.png';
import { Link } from 'react-router-dom';


import animateCircles from '../Custom-JS/custom-mouse/mouse';
import AnimatedHamburgerButton from '../Custom-JS/AnimatedHamburger/HamurgerAnim';
import ProjectsHoverLink from '../Custom-JS/ProjectsHover/ProjectsHover';



function App() {

  const [darkMode, setDarkMode] = useState(() => {
    const localStorageDarkMode = localStorage.getItem('darkMode');
    return localStorageDarkMode ? JSON.parse(localStorageDarkMode) : false;
  });
  const [scrollPosition, setScrollPosition] = useState(0);

  const toggleDarkMode = () => {
    const newDarkMode = !darkMode;
    setDarkMode(newDarkMode);
    localStorage.setItem('darkMode', JSON.stringify(newDarkMode));
  };

  useEffect(() => {
    if (darkMode) {
      document.documentElement.classList.add('dark');
    } else {
      document.documentElement.classList.remove('dark');
    }
  }, [darkMode]);

  const handleScroll = () => {
    setScrollPosition(window.scrollY);
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);


  React.useEffect(() => {
    animateCircles();
  }, []);

  const nameOpacity = scrollPosition > 50 ? 0 : 1;
  const backgroundOpacity = scrollPosition > 50 ? 0.0 : 0.0;

  return (
    <>
      <div className="fade-in-fast">
        {/* Mouse Cursor */}
        <div class="circle sm:opacity-0"></div>
        <div class="circle sm:opacity-0"></div>
        <div class="circle sm:opacity-0"></div>
        <div class="circle sm:opacity-0"></div>
        <div class="circle sm:opacity-0"></div>
        <div class="circle sm:opacity-0"></div>
        <div class="circle sm:opacity-0"></div>
        <div class="circle sm:opacity-0"></div>
        <div class="circle sm:opacity-0"></div>
        <div class="circle sm:opacity-0"></div>
        <div class="circle sm:opacity-0"></div>
        <div class="circle sm:opacity-0"></div>
        <div class="circle sm:opacity-0"></div>
        <div class="circle sm:opacity-0"></div>
        <div class="circle sm:opacity-0"></div>
        <div class="circle sm:opacity-0"></div>
        <div class="circle sm:opacity-0"></div>
        <div class="circle sm:opacity-0"></div>
        <div class="circle sm:opacity-0"></div>
        <div class="circle sm:opacity-0"></div>



        {/* Nav Bar */}
        <div className={`w-full flex justify-around h-32 items-center top-0 fixed z-20 ${darkMode ? 'dark:bg-black' : ''} sm:h-20`}
        style={{ backgroundColor: `rgba(0, 0, 0, ${backgroundOpacity})`, transition: 'background-color 0.5s' }}>
          <div className="w-36 relative 
            sm:mt-3 sm:w-24">
            <img src={darkMode ? darklogo : logo}
              alt="Logo"
              className="image relative cursor-pointer z-50"
              onClick={toggleDarkMode} />


          </div>
          <div className="font-quicksand text-4xl mt-4 mx-56 hover-grad-txt cursor-pointer
            sm:text-2xl sm:mx-auto sm:h-10 sm:pt-1 z-50 
            "
            style={{ opacity: nameOpacity, transition: 'opacity 0.7s' }}>
            <Link to="/">anand raja</Link>
          </div>



          <div className="w-40 mt-5 
            sm:w-24 sm:mt-9">
            <AnimatedHamburgerButton />
          </div>
        </div>




        <section id="about" className="bg-white dark:bg-black">
          <div className="py-52">
            
            <ProjectsHoverLink />
          </div>
        </section>


        <section id="quote-section" className="bg-white text-center text-herogray py-20 dark:bg-black dark:text-darkhero">
          <blockquote className="text-3xl italic
          sm:text-xl">
          "Quality is not an act, it is a habit." - Aristotle
          </blockquote>
        </section>
      


        <footer class="bg-white dark:bg-black text-herogray dark:text-darkhero py-4 text-center">
          <p>Copyright &copy; 2024 | Designed and Developed by Anand Raja</p>
        </footer>

      </div>

    </>

  );
}

export default App;
