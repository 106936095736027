import React, { useState } from "react";
import { motion } from "framer-motion";

const DURATION = 0.25;
const STAGGER = 0.025;

const FlipLink = ({ children, href }) => {
  const isInternalLink = href === "#";

  const linkProps = {
    href: isInternalLink ? window.location.pathname : href,
    target: isInternalLink ? "_self" : "_blank",
  };

  return (
    <motion.a
      initial="initial"
      whileHover="hovered"
      {...linkProps}
      className="relative block overflow-hidden whitespace-nowrap text-xl font-black uppercase sm:text-5xl md:text-8xl lg:text-9xl"
      style={{ lineHeight: 0.75 }}
    >
      <div>
        {children.split("").map((l, i) => (
          <motion.span
            key={i}
            variants={{
              initial: { y: 0 },
              hovered: { y: "-100%" },
            }}
            transition={{
              duration: DURATION,
              ease: "easeInOut",
              delay: STAGGER * i,
            }}
            className="inline-block"
          >
            {l}
          </motion.span>
        ))}
      </div>
      <div className="absolute inset-0">
        {children.split("").map((l, i) => (
          <motion.span
            key={i}
            variants={{
              initial: { y: "100%" },
              hovered: { y: 0 },
            }}
            transition={{
              duration: DURATION,
              ease: "easeInOut",
              delay: STAGGER * i,
            }}
            className="inline-block"
          >
            {l}
          </motion.span>
        ))}
      </div>
    </motion.a>
  );
};

const RevealLinks = () => (
  <section className="grid place-content-center gap-2 bg-white px-7 py-10 text-herogray text-center dark:bg-black">
    <FlipLink href="/">HOME</FlipLink>
    <FlipLink href="/about">ABOUT</FlipLink>
    <FlipLink href="https://github.com/AnandRajaM">GITHUB</FlipLink>
    <FlipLink href="https://www.linkedin.com/in/anandrajam/">LINKEDIN</FlipLink>
  </section>
);

const Footer = () => {
  const [fullName, setFullName] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");

  const handleSubmit = () => {
    // TO-DO - send mails

    setFullName("");
    setEmail("");
    setMessage("");
  };

  return (
    <>
      <section className="py-6 dark:bg-black dark:text-darkhero pt-64">
        <div className="grid max-w-6xl grid-cols-1 px-6 mx-auto lg:px-8 md:grid-cols-2 md:divide-x">
          <div className="py-6 md:py-0 md:px-6 flex flex-col justify-center items-center">
            <h1 className="text-6xl font-bold text-herogray dark:text-darkhero text-left">Get in touch</h1>
            <p className="pt-2 pb-4 text-xl text-herogray dark:text-darkhero text-left">Got a project in mind? I'll get the Chai ready!</p>
            <img src="https://cdn-icons-png.flaticon.com/512/8868/8868830.png" className="h-56" alt="Contact Icon" />
          </div>
          <form
            onSubmit={(e) => {
              e.preventDefault();
              handleSubmit();
            }}
            noValidate=""
            className="flex flex-col py-6 space-y-6 md:py-0 md:px-6"
          >
            <label className="block">
              <span className="mb-1">Full name</span>
              <input
                type="text"
                placeholder="John Doe"
                className="block w-full rounded-md shadow-sm dark:bg-black focus:dark:ring focus:dark:ring-darkhero"
                value={fullName}
                onChange={(e) => setFullName(e.target.value)}
              />
            </label>
            <label className="block">
              <span className="mb-1">Email address</span>
              <input
                type="email"
                placeholder="johndoe@xyz.com"
                className="block w-full rounded-md shadow-sm focus:dark:ring focus:dark:ring-opacity-75 focus:dark:ring-darkhero  dark:bg-black"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
            </label>
            <label className="block">
              <span className="mb-1">Message</span>
              <textarea
                rows="3"
                placeholder="What's cooking? I'd love to hear from you!"
                className="block w-full rounded-md focus:dark:ring focus:dark:ring-darkhero  dark:bg-black"
                value={message}
                onChange={(e) => setMessage(e.target.value)}
              ></textarea>
            </label>
            <button
              type="submit"
              className="self-center px-8 py-3 text-lg rounded focus:ring hover:ring focus:ring-opacity-75 bg-herogray text-white dark:bg-darkhero dark:text-black focus:dark:ring-white hover:dark:ring-white"
            >
              Submit
            </button>
          </form>
        </div>
      </section>

      <section id="a" className="h-hero flex flex-col justify-center items-center my-auto dark:bg-black text-center pt-32">
        <div className="text-5xl hover-grad-txt-2 h-14 sm:h-10 sm:text-3xl">+91 7306769083</div>
        <div className="text-5xl hover-grad-txt-2 h-16 sm:h-10 cursor-pointer sm:text-3xl" onClick={() => { window.location.href = 'mailto:anandrmq@gmail.com'; }}>
          anandrmq@gmail.com
        </div>
        <div className="text-5xl hover-grad-txt-2 cursor-pointer sm:text-3xl" onClick={() => { window.location.href = 'mailto:anandrm@outlook.com'; }}>
          anandrm@outlook.com
        </div>
        <div className="mt-10">
          <RevealLinks />
        </div>
      </section>
    </>
  );
};

export default Footer;
