import { useMotionValue, motion, useSpring, useTransform } from "framer-motion";
import React, { useRef } from "react";
import { FiArrowRight } from "react-icons/fi";

import reportifyimg from "../../images/reportify.png";
import healthieimg from "../../images/Healthie.png";
import websiteimg from "../../images/website.png";


const ProjectsHoverLink = () => {
  return (
    <section className="bg-white p-4 md:p-8 dark:bg-black">
      <div className="mx-auto max-w-5xl text-xl text-herogray dark:text-darkhero">
      These projects are a reflection of both my individual work and collaborations with amazing individuals I've had the pleasure of working with.
       Many of them stem from tech events like hackathons, while others are from personal endeavors. 
       Each project represents a journey of creativity, problem-solving, and innovation in technology.
      </div>
      <div className="mx-auto max-w-5xl pt-5">
        <Link
          heading="Reportify"
          subheading="Smart Diagnostic reports for all."
          imgSrc={reportifyimg}
          href="https://github.com/AnandRajaM/reportify"
          
        />
        <Link
          heading="Healthie"
          subheading="A AI Health assistant for all Medical needs."
          imgSrc={healthieimg}
          href="https://github.com/AnandRajaM/Healthie"
          
        />
        <Link
          heading="Portfolio"
          subheading="My Portfolio built with ❤️" 
          imgSrc={websiteimg}
          href="#"
        />
        <Link
          heading="Comming-Soon!"
          subheading="Being built by some cool people."
          imgSrc="https://images.unsplash.com/photo-1614332287897-cdc485fa562d?q=80&w=2070&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
          href=""
        />
        <Link
          heading="Incoming!"
          subheading="Rome wasn't built in a day!"
          imgSrc="https://images.unsplash.com/photo-1485115905815-74a5c9fda2f5?q=80&w=1918&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
          href=""
        />
      </div>
    </section>
  );
};

export default ProjectsHoverLink;

const Link = ({ heading, imgSrc, subheading, href }) => {
    const ref = useRef(null);
  
    const x = useMotionValue(0);
    const y = useMotionValue(0);
  
    const mouseXSpring = useSpring(x);
    const mouseYSpring = useSpring(y);
  
    const top = useTransform(mouseYSpring, [0.5, -0.5], ["40%", "60%"]);
    const left = useTransform(mouseXSpring, [0.5, -0.5], ["60%", "70%"]);
  
    const handleMouseMove = (e) => {
      const rect = ref.current.getBoundingClientRect();
  
      const width = rect.width;
      const height = rect.height;
  
      const mouseX = e.clientX - rect.left;
      const mouseY = e.clientY - rect.top;
  
      const xPct = mouseX / width - 0.5;
      const yPct = mouseY / height - 0.5;
  
      x.set(xPct);
      y.set(yPct);
    };
  
    return (
      <motion.a
        href={href}
        ref={ref}
        target="_blank"  
        rel="noopener noreferrer" 
        onMouseMove={handleMouseMove}
        initial="initial"
        whileHover="whileHover"
        className="group relative flex items-center justify-between border-b-2 border-neutral-700 py-4 transition-colors duration-500 hover:border-black md:py-8 dark:hover:border-darkhero"
      >
        <div>
          <motion.span
            variants={{
              initial: { x: 0 },
              whileHover: { x: -16 },
            }}
            transition={{
              type: "spring",
              staggerChildren: 0.075,
              delayChildren: 0.25,
            }}
            className="relative z-10 block text-4xl font-bold text-neutral-500 transition-colors duration-500 group-hover:text-black md:text-6xl dark:group-hover:text-darkhero"
          >
            {heading.split("").map((l, i) => (
              <motion.span
                variants={{
                  initial: { x: 0 },
                  whileHover: { x: 16 },
                }}
                transition={{ type: "spring" }}
                className="inline-block"
                key={i}
              >
                {l}
              </motion.span>
            ))}
          </motion.span>
          <span className="relative z-10 mt-2 block text-base text-neutral-500 transition-colors duration-500 group-hover:text-black dark:group-hover:text-darkhero">
            {subheading}
          </span>
        </div>
  
        <motion.img
          style={{
            top,
            left,
            translateX: "-50%",
            translateY: "-50%",
          }}
          variants={{
            initial: { scale: 0, rotate: "-12.5deg" },
            whileHover: { scale: 1, rotate: "12.5deg" },
          }}
          transition={{ type: "spring" }}
          src={imgSrc}
          className="absolute z-0 h-24 w-32 rounded-lg object-cover md:h-48 md:w-64"
          alt={`Image representing a link for ${heading}`}
        />
  
        <motion.div
          variants={{
            initial: {
              x: "25%",
              opacity: 0,
            },
            whileHover: {
              x: "0%",
              opacity: 1,
            },
          }}
          transition={{ type: "spring" }}
          className="relative z-10 p-4"
        >
          <FiArrowRight className="text-5xl text-neutral-500 dark:text-darkhero" />
        </motion.div>
      </motion.a>
    );
  };
  
