import React, { useState, useEffect } from 'react';
import { Link } from 'react-scroll';
import { FiMenu } from 'react-icons/fi';

const Sidebar = ({ scrollPosition }) => {
  const [activeSection, setActiveSection] = useState('');
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const sections = document.querySelectorAll('section');
      sections.forEach((section) => {
        const rect = section.getBoundingClientRect();
        if (rect.top <= window.innerHeight / 2 && rect.bottom >= window.innerHeight / 2) {
          setActiveSection(section.id);
        }
      });
    };

    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const getLinkClass = (id) => {
    const isActive = activeSection === id;
    return isActive ? 'text-2xl text-black font-bold dark:text-darkhero' : 'text-lg text-herogray dark:text-darkhero';
  };

  return (
    <>
      <div className="fixed top-4 left-4 md:hidden z-50">
        <button
          onClick={() => setIsOpen(!isOpen)}
          className="text-3xl text-black dark:text-white"
        >
          <FiMenu />
        </button>
      </div>
      <div
        className={`fixed top-48 left-32 h-full bg-white dark:bg-black p-4 space-y-4 transform transition-transform  ${
          isOpen ? 'translate-x-0' : '-translate-x-full'
        } md:translate-x-0 md:relative md:flex md:flex-col md:w-1/4 lg:w-1/5`}
      >
        <Link to="about" smooth className={`${getLinkClass('about')} cursor-pointer`} onClick={() => setIsOpen(false)}>
          About
        </Link>
        <Link to="certificate" smooth className={`${getLinkClass('certificate')} cursor-pointer`} onClick={() => setIsOpen(false)}>
          Certificates
        </Link>
        <Link to="awards" smooth className={`${getLinkClass('awards')} cursor-pointer`} onClick={() => setIsOpen(false)}>
          Awards
        </Link>
      </div>
    </>
  );
};

export default Sidebar;
